import React from 'react'
import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby'
import { Container } from 'reactstrap'
import styled from 'styled-components'

import scssVars from '~components/scss-variables'
import { Breadcrumbs } from '~components/Services/Intro'
import Layout from '~components/Layout'
import SEO from '~components/seo'
import { CollapsibleContent } from '~components/Collapsible/policies'

const Breadbox = styled.div`
  ul {
    display: block !important;
  }
  padding: 20px 0 10px 0;
  ul, div {
    margin-left: 0;
  }
  a {
    color: ${scssVars.darkBrown};
    font-family: "Montserrat", serif;
    &:hover, &:focus {
      color: ${scssVars.darkBrown};
    }
  }
`

const PolicyContainer = styled(Container)`
  max-width: 1280px !important;
`

const ContentWrapper = styled.div`
  h1, h2, p {
    color: ${scssVars.darkBrown};
  }
  h1, h2 {
    font-weight: 600;
    line-height: 1.25;
  }
  h1 {
    font-size: 18px;
  }
  h2 {
    font-size: 14px;
    letter-spacing: 0;
    margin-bottom: 10px;
  }
  p {
    font-size: 16px;
    margin-bottom: 25px;
    a {
      color: ${scssVars.darkBrown};
      font-family: "EB Garamond", serif;
      &:hover, &:focus {
        color: ${scssVars.darkBrown};
      }
    }
  }
  ul {
    color: ${scssVars.darkBrown};
    li {
      font-size: 16px;
      margin-bottom: 0.5rem;
    }
  }
  hr {
    margin: 20px 0 35px;
    border-color: ${scssVars.beige};
  }
  tbody {
    font-size: 16px;
    color: ${scssVars.darkBrown};
    tr {
      th {
        border: 1px solid ${scssVars.beige};
        padding-left: 1em;
      }
      td {
        border: 1px solid ${scssVars.beige};
        padding-left: 1em;
        &:first-child {
          width: 30%;
        }
        &:nth-child(2) {
          width: 70%;
          vertical-align: middle;
        }
        ul {
          margin: 0;
          padding: 1em;
        }
      }
    }
  }
`

const Content = ({ markdownRemark }) => {
  const isOperationalPolicies = (markdownRemark.frontmatter.path === '/operational-policies')
  const isClientSide = (typeof window !== 'undefined')

  if (isClientSide) {
    return (
      <>
        {isOperationalPolicies ?
          <>
            <p><em>Operational Policies V 1-05.20 - Last Updated May 1st, 2020</em></p>
            <h1 id="operational-policies">Operational Policies</h1>
            <hr />
            <CollapsibleContent htmlString={markdownRemark.html} />
            <p>
              If you have any questions regarding our Operational Policies, please email us at&nbsp;
              <a href="mailto:info@leatherspa.com">info@leatherspa.com</a> or contact the store where you dropped off your item(s).&nbsp;
              <a href="/locations">Locations</a>
            </p>
          </>
          : <div dangerouslySetInnerHTML={{ __html: markdownRemark.html }} />
        }
      </>
    )
  } else {
    return (
      <div dangerouslySetInnerHTML={{ __html: markdownRemark.html }} />
    )
  }
}

const PolicyPage = ({ data: { markdownRemark } }) => (
  <Layout>
    <SEO title={markdownRemark.frontmatter.title} pathname={markdownRemark.frontmatter.path} description={markdownRemark.frontmatter.description} />
    <main className="pb-5">
      <PolicyContainer>
        <Breadbox>
          <Breadcrumbs>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to={markdownRemark.frontmatter.path}>{markdownRemark.frontmatter.title}</Link>
            </li>
          </Breadcrumbs>
        </Breadbox>
        <ContentWrapper>
          <Content markdownRemark={markdownRemark} />
        </ContentWrapper>
      </PolicyContainer>
    </main>
  </Layout>
)

PolicyPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      html: PropTypes.string.isRequired,
      frontmatter: {
        title: PropTypes.string.isRequired,
        path: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired
      }
    }).isRequired
  })
}

export default PolicyPage

export const policyPageQuery = graphql`
  query PolicyPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        description
        path
      }
    }
  }
`
