import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Collapse } from 'reactstrap'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons'

const CollapseIcon = styled(FontAwesomeIcon)`
  margin-right: 7px;
  width: 0.7em !important;
`

export const CollapsibleContent = ({ htmlString }) => {
  const parser = new DOMParser()
  const htmlDOM = parser.parseFromString(htmlString, 'text/html')
  const headings = Array.from(htmlDOM.getElementsByTagName('h2'))

  return headings.map((heading) => {
    const content = heading.nextElementSibling
    const [isOpen, setIsOpen] = useState(false)
    const toggle = () => setIsOpen(!isOpen)

    return (
      <div>
        <h2 onClick={toggle} style={{ marginBottom: '1rem' }}>
          <CollapseIcon className="float-left" icon={isOpen ? faMinus : faPlus} />
          {heading.textContent}
        </h2>
        <Collapse isOpen={isOpen}>
          <div dangerouslySetInnerHTML={{ __html: content.outerHTML }} />
        </Collapse>
      </div>
    )
  })
}

CollapsibleContent.propTypes = {
  htmlString: PropTypes.string.isRequired
}
