import React from 'react'
import styled from 'styled-components'
import { Button } from 'reactstrap'
import PropTypes from 'prop-types'

import scssVars from '../scss-variables'

export const MINIMUM_LEFT_OFFSET = 'calc((100vw - 1280px) / 2)'

export const Main = styled.main`
  margin-top: 25px;
  display: flex;
  margin-bottom: 50px;

  & > div {
    width: 50%;
  }

  @media only screen and (max-width: ${scssVars.tabletMax}) {
    flex-direction: column-reverse;
    margin-bottom: 10px;

    & > div {
      flex-shrink: 0;
      width: 100%;

      &:first-child {
        padding-top: 50px;
      }
    }
  }
`

export const RegularParagraph = styled.p`
  color: ${scssVars.darkBrown};
  margin-left: ${MINIMUM_LEFT_OFFSET};
  font-size: 18px;
  max-width: 480px;

  @media only screen and (max-width: ${scssVars.tabletMax}) {
    margin-left: auto;
    margin-right: auto;
    max-width: 90%;
  }

  @media only screen and (max-width: 1280px) and (min-width: ${scssVars.desktopMin}) {
    margin-left: 50px;
  }
`

export const BoldedParagraph = styled.p`
  color: ${scssVars.darkBrown};
  margin-left: ${MINIMUM_LEFT_OFFSET};
  margin-top: 195px;
  max-width: 480px;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 5px;

  @media only screen and (max-width: ${scssVars.tabletMax}) {
    margin-top: 0px;
    margin-left: auto;
    margin-right: auto;
    max-width: 90%;
  }

  @media only screen and (max-width: 1280px) and (min-width: ${scssVars.desktopMin}) {
    margin-left: 50px;
  }
`

export const Title = styled.h1`
  color: ${scssVars.brown};
  height: 136px;
  padding: 43px;
  padding-left: ${MINIMUM_LEFT_OFFSET};
  background-color: ${scssVars.gray};
  letter-spacing: 2.4px;
  font-size: 48px;
  font-weight: 300;
  position: absolute;

  @media only screen and (max-width: ${scssVars.tabletMax}) {
    position: relative;
    width: 70%;
    padding-left: 20px;
    padding-right: 5px;
    margin-top: -110px;
    font-size: 28px;
    height: auto;
  }

  @media only screen and (max-width: 1280px) and (min-width: ${scssVars.desktopMin}) {
    padding-left: 50px;
  }
`

export const ButtonContainer = styled.div`
  margin-left: ${MINIMUM_LEFT_OFFSET};
  width: 385px;
  margin-top: 35px;

  @media only screen and (max-width: ${scssVars.tabletMax}) {
    margin-left: auto;
    margin-right: auto;
    max-width: 90%;
    margin-bottom: 20px;
  }

  @media only screen and (max-width: 1280px) and (min-width: ${scssVars.desktopMin}) {
    margin-left: 50px;
  }
`

export const ActionButton = ({ onClick, label, disabled }) => (
  <ButtonContainer>
    <Button color="primary" onClick={onClick} disabled={disabled}>{label}</Button>
  </ButtonContainer>
)

ActionButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool
}

export const Picture = ({ className, src, mobileSrc }) => {
  return (<picture>
    <source srcSet={mobileSrc} media={`(max-width: ${scssVars.tabletMax})`} />
    <img className={className} src={src}></img>
  </picture>)
}

Picture.propTypes = {
  src: PropTypes.string.isRequired,
  mobileSrc: PropTypes.string.isRequired,
  className: PropTypes.string
}

export const HeroImage = styled(Picture)`
  width: 100%;
  max-width: 670px;

  @media only screen and (max-width: ${scssVars.tabletMax}) {
    display: block;
    width: 100%;
    max-width: none;
  }
`

export const MobileBreadcrumb = styled.h6`
  width: 90%;
  margin-left: 10px;
  position: relative;
  font-size: 14px;
  padding: 0;
  color: ${scssVars.darkBrown};
  position: relative;
  padding: 18px 0 10px 0;
  font-weight: 400;

  &:before {
    padding-right: 5px;
    position: relative;
    content: "< "
  }

  @media only screen and (min-width: ${scssVars.desktopMin}) {
    display: none;
  }
`

export const Breadcrumbs = styled.ul`
  color: ${scssVars.darkBrown};
  list-style: none;
  overflow: hidden;
  margin-left: ${MINIMUM_LEFT_OFFSET};
  font-size: 16px;
  padding: 0;

  @media only screen and (max-width: 1280px) {
    margin-left: 50px;
    margin-top: 6px;
  }

  @media only screen and (max-width: ${scssVars.tabletMax}) {
    display: none;
  }
  a {
    color: ${scssVars.darkBrown};
  }

  li {
    display: inline;
    font-family: 'Montserrat', sans-serif;
    font-size: 13px;
    letter-spacing: 0.025em;
    &:after {
      content: ' / ';
    }

    &:last-child:after {
      content: "";
    }
  }
`
